@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;400&&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "Roboto VF";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/57225/Roboto-VF.woff2") format("woff2-variations");
  font-stretch: 75% 100%;
  font-style: oblique 0deg 12deg;
  font-weight: 100 900;
}
/* reset */
:root {
  --text-thin: 100;
  --text-extralight: 200;
  --text-light: 300;
  --text-regular: 400;
  --text-medium: 500;
  --text-bold: 600;
  --text-semibold: 700;
  --text-extrabold: 800;
  --text-black: 900;
  --text-weight: 500;
  --text-width: 100;
  --text-slant: 0;
  --light-theme: #ecebe9;
  --dark-theme: #1d1119;
  --bg-accent: #e5e5e5;
  --space-base: 1em;
  --space-xxxxs: calc(var(--space-base)*0.125);
  --space-xxxs: calc(var(--space-base)*0.25);
  --space-xxs: calc(var(--space-base)*0.375);
  --space-xs: calc(var(--space-base)*0.5);
  --space-sm: calc(var(--space-base)*0.75);
  --space-md: calc(var(--space-base)*1.25);
  --space-lg: calc(var(--space-base)*2);
  --space-xl: calc(var(--space-base)*3.25);
  --space-xxl: calc(var(--space-base)*5.25);
  --space-xxxl: calc(var(--space-base)*8.5);
  --space-xxxxl: calc(var(--space-base)*13.75);
  --padding: var(--space-md);
  --duration: 250ms;
  --ease: cubic-bezier(0.22, 1, 0.36, 1);
  --font-size-xxxs: calc(var(--space-base)*0.25);
  --font-size-xxs: calc(var(--space-base)*0.375);
  --font-size-xs: calc(var(--space-base)*0.5);
  --font-size-sm: calc(var(--space-base)*0.75);
  --font-size-md: calc(var(--space-base)*1.25);
  --font-size-lg: calc(var(--space-base)*2);
  --font-size-xl: calc(var(--space-base)*3.25);
  --font-size-xxl: calc(var(--space-base)*5.25);
  --font-size-xxxl: calc(var(--space-base)*8.5);
  --font-size-xxxxl: calc(var(--space-base)*15);
  color-scheme: dark light;
}

.theme-light {
  --color-primary: #094d90;
  --color-secondary: #fbfbfe;
  --color-accent: #fd6f53;
  --color-text: #1d1119;
  --color-bg: #ecebe9;
  --color-text-light: hsl(320, 26%, 15%);
  --color-bg-light: hsl(40, 7%, 96%);
  --bg-img: url(/img/bg-noise.png);
  --color-dark-only: #1d1119;
  --color-light-only: #ecebe9;
  --color-primary-only: #094d90;
  --color-accent-only: #fd6f53;
  --color-neutral: #618985;
  --color-neutral-lighter: #86bdb7;
  --color-primary-darker: #1b3ab6;
  --color-primary-darker-h: 228;
  --color-primary-darker-s: 74%;
  --color-primary-darker-l: 41%;
  --color-link: #08c;
  --color-link-hover: #3dbeff;
  --color-darkest: #1a0f16;
  --color-gradient-intro-txt: linear-gradient(270deg, rgba(15, 111, 255, .46), #0ee166);
  --form-inputs-bg: #edf2f2;
  --color-lightest: #fafafa;
  --color-lightest-h: 0;
  --color-lightest-s: 0%;
  --color-lightest-l: 98%;
  --color-gradient: #094d90;
  --color-gradient-darker: #1b3ab6;
  --color-gradient-accent: #fd6f53;
  --dot-color: rgba(0,0,0,.125);
  --dot-color: #ccc;
}

.theme-dark {
  --bg-accent: #1a1a1a;
  --color-primary: #75d1ff;
  --color-secondary: #2a2c2d;
  --color-accent: #12cdea;
  --color-text: #ecebe9;
  --color-bg: #1d1119;
  --color-text-light: hsl(40, 7%, 96%);
  --color-bg-light: hsl(320, 26%, 15%);
  --bg-img: url(/img/bg-noise.png);
  --color-dark-only: #1d1119;
  --color-light-only: #ecebe9;
  --color-primary-only: #094d90;
  --color-accent-only: #fd6f53;
  --color-neutral: #618985;
  --color-neutral-lighter: #466360;
  --color-link: #3dbeff;
  --color-link-hover: #08c;
  --color-gradient-intro-txt: linear-gradient(270deg, rgba(15, 111, 255, .46), #ff9d00);
  --form-inputs-bg: #1c2726;
  --color-lightest: #1c1c1c;
  --color-lightest-h: 0;
  --color-lightest-s: 0%;
  --color-lightest-l: 11%;
  --color-gradient: hsl(210, 88%, 20%);
  --color-gradient-darker: hsl(228, 74%, 15%);
  --color-gradient-accent: hsl(10, 98%, 66%);
  --color-darkest: #fafafa;
  --dot-color: #333;
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

blockquote,
body,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
p {
  margin: 0;
}

ol[role=list],
ul[role=list] {
  list-style: none;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
}

iframe,
img,
object,
picture,
video {
  display: block;
}

img,
picture {
  aspect-ration: 1/1.25;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

@media (max-width: 1460px) {
  img,
picture {
    /* aspect-ratio: 1/1 */
    aspect-ratio: auto;
  }
}
:root {
  --b-font-main: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --b-font-mono: Consolas, Monaco, monospace;
  --b-txt: #2e3440;
  --b-bg-1: #fff;
  --b-bg-2: #eceff4;
  --b-line: #eceff4;
  --b-link: #bf616a;
  --b-btn-bg: #242933;
  --b-btn-txt: #fff;
  --b-focus: #88c0d0 ;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-accent: #1a1a1a;
    --b-txt: #eceff4;
    --b-bg-1: #2e3440;
    --b-bg-2: #3b4252;
    --b-line: #3b4252 ;
  }
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  background: var(--b-bg-1);
  color: var(--b-txt);
  font-family: var(--b-font-main);
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  word-break: break-word;
}

address,
audio,
blockquote,
dd,
details,
dl,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
iframe,
ol,
p,
pre,
table,
ul,
video {
  margin: 0 0 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  margin-top: 2rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.7;
}

h6 {
  font-size: 0.75rem;
}

a {
  color: var(--b-link);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img,
svg,
video {
  height: auto;
}

embed,
iframe,
img,
object,
svg,
video {
  max-width: 100%;
}

iframe {
  border-style: none;
}

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

blockquote {
  border-left: 0.25rem solid var(--b-txt);
  margin-left: 0;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

blockquote > :last-child {
  margin-bottom: 0;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

hr {
  border: 0;
  border-bottom: 1px solid var(--b-line);
  height: 0;
  overflow: visible;
}

code,
kbd,
pre,
samp,
tt,
var {
  background: var(--b-bg-2);
  border-radius: 0.25rem;
  font-family: var(--b-font-mono);
  font-size: 0.875rem;
  padding: 0.125rem 0.25rem;
}

pre {
  border-radius: 0;
  overflow: auto;
  padding: 1rem;
  white-space: pre;
}

pre code {
  padding: 0;
}

details {
  background: var(--b-bg-2);
  border: 1px solid var(--b-line);
  border-radius: 0.25rem;
  display: block;
  padding: 0.5rem 1rem;
}

details > :last-child {
  margin-bottom: 0;
}

details[open] > summary {
  margin-bottom: 1.5rem;
}

summary {
  cursor: pointer;
  display: list-item;
  font-weight: 700;
}

summary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

table {
  border-collapse: collapse;
  text-indent: 0;
  width: 100%;
}

table caption {
  margin-bottom: 0.5rem;
}

tr {
  border-bottom: 1px solid var(--b-line);
}

td,
th {
  padding: 0.5rem 0;
}

th {
  text-align: left;
}

dd,
ol,
ul {
  padding-left: 2rem;
}

li > ol,
li > ul {
  margin-bottom: 0;
}

fieldset {
  border: 1px solid var(--b-line);
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
}

legend {
  padding: 0 0.25rem;
}

label {
  cursor: pointer;
  display: block;
  margin-bottom: 0.25rem;
}

button,
input,
select,
textarea {
  background: var(--b-bg-2);
  border: 0;
  border-radius: 0.25rem;
  color: var(--b-txt);
  font: inherit;
  line-height: 1.125;
  margin: 0;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
}

input:not([size]):not([type=button i]):not([type=submit i]):not([type=reset i]):not([type=checkbox i]):not([type=radio i]),
select {
  width: 100%;
}

[type=color i] {
  min-height: 2.125rem;
}

select:not([multiple]):not([size]) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  padding-right: 1.5rem;
}

textarea {
  resize: vertical;
  width: 100%;
}

textarea:not([rows]) {
  height: 8rem;
}

[type=button i],
[type=reset i],
[type=submit i],
button {
  -webkit-appearance: button;
  background: var(--b-btn-bg);
  border: 0;
  color: var(--b-btn-txt);
  cursor: pointer;
  display: inline-block;
  text-align: center;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  white-space: nowrap;
}

[type=button i]:hover,
[type=reset i]:hover,
[type=submit i]:hover,
button:hover {
  opacity: 0.75;
}

[type=button i][disabled],
[type=reset i][disabled],
[type=submit i][disabled],
button[disabled] {
  opacity: 0.5;
}

progress {
  vertical-align: middle;
}

[type=search i] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

[aria-busy=true i] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled=true i],
[disabled] {
  cursor: not-allowed;
}

:focus,
details:focus-within {
  -webkit-box-shadow: 0 0 0 2px var(--b-focus);
  box-shadow: 0 0 0 2px var(--b-focus);
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
:after,
:before {
    -webkit-animation-delay: -1ms !important;
    animation-delay: -1ms !important;
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
    background-attachment: scroll !important;
    scroll-behavior: auto !important;
    -webkit-transition-delay: 0 !important;
    transition-delay: 0 !important;
    -webkit-transition-duration: 0 !important;
    transition-duration: 0 !important;
  }
}
select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%232e3440'%3E%3Cpath d='m5 6 5 5 5-5 2 1-7 7-7-7 2-1z'/%3E%3C/svg%3E");
}

@media (prefers-color-scheme: dark) {
  select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23eceff4'%3E%3Cpath d='m5 6 5 5 5-5 2 1-7 7-7-7 2-1z'/%3E%3C/svg%3E");
  }
}
body {
  font-family: "Roboto VF", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

p,
li,
h1,
h2,
h3,
h4 {
  overflow-wrap: break-word;
  hyphens: initial;
  line-height: 1.5;
}

.accent-color {
  color: var(--color-accent);
}

.uppercase {
  text-transform: uppercase;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: start;
}

.txt-right {
  text-align: end;
}

.center-h {
  text-align: center;
}

.center-v {
  vertical-align: middle;
}

.center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  display: block;
  text-align: center;
}

/*
.bubble {
    position: fixed;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    background: var(--color-link-hover);
    transition: height 0.4s, width 0.4s, background-color 0.4s, all 0.2s;
    z-index: 99999;
}

.bubble--big {
    width: 100px;
    height: 100px;
    background: var(--color-primary);
    transition: height 0.4s, width 0.4s, background-color 0.4s;
}


.cursor {
    position: fixed;
    top: -1rem;
    left: -1rem;
    border-radius: 50%;
    pointer-events: none;
    z-index: 9991000;
}

.cursor div {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    border: 1px solid var(--color-link-hover);
    transition: transform 0.5s ease, opacity 0.35s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cursor div .inner {
    width: 5px;
    height: 5px;
    background: var(--color-primary);
}

.cursor.is-active div {
    transform: scale(2.5);
    opacity: 0.25;
}
*/
*:not(input, textarea) {
  cursor: none;
}

#cursor {
  position: fixed;
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  border-radius: 50%;
  pointer-events: none;
  z-index: 999;
}

#cursor-border {
  --size: 50px;
  position: fixed;
  top: calc(var(--size) / -2);
  left: calc(var(--size) / -2);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--color-link-hover);
  pointer-events: none;
  transition: top 0.15s ease-out, left 0.15s ease-out, width 0.15s ease-out, height 0.15s ease-out, background-color 0.15s ease-out;
  z-index: 999;
}

@media (max-width: 768px) {
  #cursor,
#cursor-border {
    display: none;
  }
}
/* contact form */
.contact-form form {
  --transition-type:all;
  --transition-duration:0.33s;
  --transition-timing-function:ease-in-out;
  --transition-delay:0s;
  -webkit-transition: var(--transition-type) var(--transition-duration) var(--transition-timing-function);
  transition: var(--transition-type) var(--transition-duration) var(--transition-timing-function);
}

@media (max-width: 768px) {
  .contact-form form {
    margin-top: var(--space-lg);
  }
}
.contact-form form .disabled {
  opacity: 0.7;
  pointer-events: none;
}

.contact-form form .dbl-field {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(var(--space-sm) * 2);
}

.contact-form form input, .contact-form form textarea {
  background-color: var(--form-inputs-bg);
  border: none;
  border-bottom: 1px solid var(--color-neutral);
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: var(--color-text);
  outline: none;
}

.contact-form form input:not(:-moz-placeholder-shown) + label, .contact-form form textarea:not(:-moz-placeholder-shown) + label {
  color: var(--color-text);
  opacity: 0.83;
  transform: scale(0.83) translateY(-180%) translateX(-6px);
}

.contact-form form input:not(:-ms-input-placeholder) + label, .contact-form form textarea:not(:-ms-input-placeholder) + label {
  color: var(--color-text);
  opacity: 0.83;
  transform: scale(0.83) translateY(-180%) translateX(-6px);
}

.contact-form form input:focus + label, .contact-form form input:not(:placeholder-shown) + label, .contact-form form textarea:focus + label, .contact-form form textarea:not(:placeholder-shown) + label {
  color: var(--color-text);
  opacity: 0.83;
  -webkit-transform: scale(0.83) translateY(-180%) translateX(-6px);
  transform: scale(0.83) translateY(-180%) translateX(-6px);
}

.contact-form form label {
  display: block;
  left: 11px;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 15px;
}

.contact-form form .field, .contact-form form label {
  -webkit-transition: var(--transition-type) var(--transition-duration) var(--transition-timing-function);
  transition: var(--transition-type) var(--transition-duration) var(--transition-timing-function);
}

.contact-form form .field {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: calc(50% - 11px);
}

.contact-form form .field input {
  min-height: 50px;
  width: 100%;
}

.contact-form form .message textarea, .contact-form form input {
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: calc(var(--font-size-md) * 0.75);
  outline: none;
  padding: var(--space-sm);
  -webkit-transition: var(--transition-type) var(--transition-duration) var(--transition-timing-function);
  transition: var(--transition-type) var(--transition-duration) var(--transition-timing-function);
}

.contact-form form .message textarea::-webkit-input-placeholder, .contact-form form input::-webkit-input-placeholder {
  color: var(--color-neutral);
}

.contact-form form .message textarea::-moz-placeholder, .contact-form form input::-moz-placeholder {
  color: var(--color-neutral);
}

.contact-form form .message textarea:-ms-input-placeholder, .contact-form form input:-ms-input-placeholder {
  color: var(--color-neutral);
}

.contact-form form .message textarea::-ms-input-placeholder, .contact-form form input::-ms-input-placeholder {
  color: var(--color-neutral);
}

.contact-form form .message textarea::placeholder, .contact-form form input::placeholder {
  color: var(--color-neutral);
}

.contact-form form .message textarea:focus, .contact-form form input:focus {
  border-color: var(--color-primary);
  border-width: 3px;
}

.contact-form form .message textarea:focus ~ i, .contact-form form input:focus ~ i {
  color: var(--color-primary);
}

.contact-form form i {
  color: var(--color-neutral);
  font-size: 17px;
  left: 18px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.contact-form form .message {
  position: relative;
}

.contact-form form .message i {
  font-size: 20px;
  top: 30px;
}

.contact-form form .message textarea {
  max-height: 230px;
  max-width: 100%;
  min-height: 130px;
  min-width: 100%;
  padding: 15px var(--space-sm);
}

.contact-form form .message textarea::-webkit-scrollbar {
  width: 0;
}

.contact-form form .button-area {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: var(--space-md) 0;
}

.contact-form form .button-area button {
  background: var(--color-primary);
  border: none;
  border-radius: 0;
  color: var(--color-lightest);
  cursor: pointer;
  font-size: 18px;
  outline: none;
  padding: var(--space-sm) var(--space-md);
}

.contact-form form .button-area button:hover {
  background: #0a549e;
}

.contact-form form .button-area span.form-message {
  color: var(--color-neutral);
  display: none;
  font-size: 17px;
  margin-left: 30px;
}

@media (max-width: 768px) {
  .contact-form form .field, .contact-form form .message {
    margin-bottom: var(--space-lg);
  }

  .contact-form form .dbl-field {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0;
  }

  .contact-form form .dbl-field .field {
    height: 45px;
    width: 100%;
  }

  .contact-form form .message textarea {
    resize: none;
  }

  .contact-form form .button-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 20px;
  }

  .contact-form form .button-area button {
    font-size: 16px;
    padding: 11px 0;
    width: 100%;
  }

  .contact-form form .button-area span {
    margin: 20px 0 0;
    text-align: center;
  }
}
.contact-info img[src$=".svg"] {
  aspect-ratio: auto;
  max-height: 1.5em;
  max-width: 1.5em;
}

@media (max-width: 768px) {
  .portfolio {
    margin-bottom: var(--space-xxl);
  }
}
.contact {
  display: grid;
  min-height: 70vh;
}

@media (max-width: 768px) {
  .contact {
    margin-bottom: 0;
    margin-top: var(--space-xs);
    min-height: 10vh;
    padding-bottom: 0;
  }
}
.contact .contact-me {
  margin-top: var(--space-xxxl);
}

@media (max-width: 1024px) {
  .contact .contact-me {
    margin-top: var(--space-xl);
  }
}
@media (max-width: 768px) {
  .contact .contact-me {
    margin-top: var(--space-xs);
  }
}
.contact .contact-me .contact-info-container h1, .contact .contact-me .contact-info-container h2, .contact .contact-me .contact-info-container h3, .contact .contact-me .contact-info-container h4, .contact .contact-me .contact-info-container h5, .contact .contact-me .contact-info-container h6 {
  font-family: Rubik, sans-serif;
  font-size: var(--font-size-lg);
  font-size: clamp(0.8rem, 0.8rem + 4vw, 2rem);
  font-weight: var(--font-weight-regular);
  margin-block: var(--space-sm);
  text-transform: uppercase;
}

.contact .contact-me .contact-info {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: var(--space-lg);
}

@media (max-width: 768px) {
  .contact .contact-me .contact-info {
    gap: var(--space-lg);
  }
}
.contact .contact-me .contact-info img[src$=".svg"] {
  aspect-ratio: auto;
  max-height: 1.5em;
  max-width: 1.5em;
}

.contact .contact-me .contact-info .contact-method > div:last-of-type p {
  margin-bottom: 0;
}

.contact .contact-me .contact-info .contact-method-email, .contact .contact-me .contact-info .contact-method-phone {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-transition: all 0.33s ease-in-out;
  transition: all 0.33s ease-in-out;
}

.contact .contact-me .contact-info .contact-method-email:hover, .contact .contact-me .contact-info .contact-method-phone:hover {
  -webkit-filter: opacity(0.5);
  filter: opacity(0.5);
}

.contact .contact-me .contact-info .contact-method img[src$=".svg"] {
  margin-right: var(--space-md);
}

.contact .contact-me .contact-info .contact-method a {
  color: var(--color-text);
  /*box-shadow: inset 0 0 0 0 var(--color-primary-darker);transition: color .3s ease-in-out, box-shadow .3s ease-in-out;padding: 0.15em 0.35rem;*/
  font-size: var(--font-size-md);
  line-height: 1;
  text-decoration: none;
}

.contact .contact-me .contact-info .contact-method a:hover {
  color: var(--color-primary);
  /*box-shadow: inset 300px 0 0 0 var(--color-primary-darker);*/
}

.contact .contact-me .contact-info .contact-social-media ul.social-media-icons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--space-md);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.contact .contact-me .contact-info .contact-social-media ul.social-media-icons li {
  -ms-flex-item-align: center;
  align-self: center;
}

.contact .contact-me .contact-info .contact-social-media ul.social-media-icons li a img[src$=".svg"] {
  aspect-ratio: auto;
  height: 1.5em;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  -webkit-transition: all 0.33s ease-in-out;
  transition: all 0.33s ease-in-out;
}

.contact .contact-me .contact-info .contact-social-media ul.social-media-icons li a img[src$=".svg"]:hover {
  -webkit-filter: opacity(0.5);
  filter: opacity(0.5);
}

.contact .contact-me .contact-info .resume-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--space-md);
}

@media (max-width: 768px) {
  .contact .contact-me .contact-info .resume-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: var(--space-xxs);
  }
}
.contact .contact-me .contact-info .resume-links a.btn-contact {
  background-color: var(--color-primary);
  color: var(--color-bg);
  font-size: calc(var(--font-size-sm) * 1.2);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--space-sm);
  padding: var(--space-sm);
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: all 0.33s ease-in-out;
  transition: all 0.33s ease-in-out;
}

@media (max-width: 768px) {
  .contact .contact-me .contact-info .resume-links a.btn-contact {
    text-align: center;
  }
}
.contact .contact-me .contact-info .resume-links a.btn-contact:hover {
  background: #0b59a8;
}

.contact .contact-me .contact-details {
  font-size: clamp(0.5rem, 0.5rem + 4vw, 2.2rem);
  line-height: 1.6;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .contact .contact-me .contact-details span {
    display: none;
  }
}
.contact .contact-me .contact-details .btn-contact {
  background: var(--color-primary);
  border-radius: 0.2em;
  color: var(--color-bg);
  display: inline-block;
  margin-block: calc(var(--space-xxs) * 0.5);
  padding: calc(var(--space-xxs) * 0.5);
  text-decoration: none;
}

.contact .contact-me .contact-details .btn-contact:hover {
  background: var(--color-bg);
  color: var(--color-primary);
}

.contact-method-resume {
  margin-top: 1.4em;
}

.contact .contact-me .contact-info .contact-method .contact-method-resume a {
  background: var(--color-primary);
  border: none;
  border-radius: 0;
  color: var(--color-lightest);
  cursor: pointer;
  font-size: 18px;
  outline: none;
  padding: var(--space-sm) var(--space-md);
  transition: all 0.33s ease-in-out;
}

.contact .contact-me .contact-info .contact-method .contact-method-resume a:hover {
  background: #0a549e;
  opacity: 0.75;
}

footer {
  margin-top: var(--space-xl);
}

body {
  color: var(--color-text);
  background: var(--color-bg) var(--bg-img) repeat;
  /* background: var(--color-bg); */
  transition: background 1300ms ease-in-out, color 500ms ease-in-out;
}

p, h1, h2, h3, h4, h5, h6, a, button, input, textarea, select {
  transition: background 1300ms ease-in-out, color 500ms ease-in-out;
}

nav, main {
  color: var(--color-text);
}

body.theme-dark :is(img, picture, video, iframe, object, .demo-reel-video) {
  filter: brightness(83%);
}

body.theme-dark .contact img[src$=".svg"] {
  filter: invert(1) brightness(83%);
}

body.theme-dark .contact img[src$=".svg"]:hover {
  filter: invert(1) brightness(100%) !important;
}

body h1 {
  color: var(--color-text);
}

.container button {
  color: var(--color-text);
  background: var(--color-primary);
}

input[type=checkbox]:focus {
  outline: 0;
  appearance: none;
}

.theme-changer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.theme-changer p {
  color: #c59bb7;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  margin: auto 1em;
  text-transform: uppercase;
}

@media (max-width: 670px) {
  .theme-changer p {
    display: none;
  }
}
.theme-changer p:first-child {
  margin-left: 0;
}

.theme-changer p:last-child {
  margin-right: 0;
}

.theme-changer .theme-switch {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 50px;
}

@media (max-width: 768px) {
  .theme-changer .theme-switch label {
    margin-bottom: 0;
  }
}
.theme-changer .theme-switch .round {
  border-radius: 34px;
}

.theme-changer .theme-switch .round:before {
  border-radius: 50%;
}

.theme-changer .theme-switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.theme-changer .theme-switch .theme-slider {
  background-color: var(--dark-theme);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.theme-changer .theme-switch .theme-slider:before {
  background: #fff url(/img/ico-sun.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: 4px;
  bottom: 0;
  -webkit-box-shadow: 0 0 8px #2020203d;
  box-shadow: 0 0 8px #2020203d;
  content: "";
  height: 30px;
  left: 0;
  margin: auto 0;
  position: absolute;
  top: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 30px;
}

.theme-changer .theme-switch input:checked + .theme-slider {
  background-color: var(--light-theme);
}

.theme-changer .theme-switch input:focus + .theme-slider {
  -webkit-box-shadow: 0 0 1px var(--light-theme);
  box-shadow: 0 0 1px var(--light-theme);
}

.theme-changer .theme-switch input:checked + .theme-slider:before {
  background: #fff url(/img/ico-moon.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
}

.hero {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  max-width: 83ch;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav-note-settings nav ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
  margin: 0 0 3em;
  padding: 0;
}

.nav-note-settings nav li a {
  padding: 0.5em 0.8em;
  background-color: var(--color-secondary);
  text-transform: uppercase;
  border-radius: 0.5em;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.33s ease;
  color: var(--color-primary);
  font-weight: 400;
  /* box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.nav-note-settings nav li a:hover {
  background-color: var(--color-primary);
  color: var(--color-bg);
}

.note-settings {
  text-align: center;
}

ul, header h2 {
  font-family: "Rubik", sans-serif;
}

main {
  /* width: min(83%, 100vw - 8rem); */
  /* margin-inline: auto; */
}

@media (max-width: 1200px) {
  .clean-list {
    padding: 2em 3em;
  }
}
@media (max-width: 768px) {
  main {
    /* width: min(83%, 100vw - 2rem); */
  }

  header {
    margin: 3em;
  }

  header h2 {
    margin-top: 1em;
  }

  .clean-list {
    padding: 2em 3em;
    margin: 0;
  }
}
.container main > section:not(.full-width).marquee-section {
  width: 100%;
}

.container .site-header .header-wrapper,
.container main > section:not(.full-width) {
  margin-inline: auto;
  width: min(83%, 100vw - 8rem);
}

.inner-container {
  margin-inline: auto;
  width: min(83%, 100vw - 8rem);
}

.marquee-section {
  margin-block: var(--space-xxxl);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 768px) {
  .marquee-section {
    margin-block: var(--space-xxl);
  }
}
.dashed-circle {
  border-radius: 50%;
}

.dashed-circle svg circle {
  fill: transparent;
  stroke: var(--color-primary);
  stroke-width: 0.15;
}

.dashed-circle .dashed {
  stroke-dasharray: 0.83, 0.5;
}

.shapes {
  margin-top: calc(var(--space-xxl) * -4);
  position: relative;
  z-index: 22;
}

@media (max-width: 768px) {
  .shapes {
    min-height: 4em;
    pointer-events: none !important;
  }
}
.shapes .dashed-circle {
  border-radius: 50%;
  min-height: 200px;
  min-width: 200px;
  position: absolute;
  right: 10em;
  top: 20em;
}

@media (max-width: 768px) {
  .shapes .dashed-circle {
    right: 9em;
    top: 6em;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.shapes .dashed-circle svg {
  -webkit-transform: scale(4.83);
  transform: scale(4.83);
}

.shapes .arrow-shape {
  height: auto;
  position: absolute;
  right: 15em;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  width: auto;
}

@media (max-width: 768px) {
  .shapes .arrow-shape {
    max-width: 4em;
    right: 2em;
  }

  .intro-text-words > span:last-child {
    display: none;
  }
}
.marquee-section {
  margin-block: var(--space-xxxl);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 768px) {
  .marquee-section {
    margin-block: var(--space-xxl);
  }
}
.about {
  --width: 33em;
  --height: 33em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: calc(var(--space-xxxl) * 2);
  padding-block: var(--space-xxl);
  position: relative;
}

@media (max-width: 1024px) {
  .about {
    --width: 24em;
    --height: 24em ;
  }
}
@media (max-width: 768px) {
  .about {
    --width: 33em;
    --height: 33em ;
  }
}
@media (max-width: 575px) {
  .about {
    --width: 20em;
    --height: 20em ;
  }
}
@media (max-width: 768px) {
  .about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: var(--space-md);
  }
}
.about .my-photo {
  position: relative;
}

.about .my-photo .emblem {
  position: absolute;
  right: -3em;
  top: -3em;
  z-index: 11;
}

@media (max-width: 768px) {
  .about .my-photo .emblem {
    right: 7em;
    top: 1em;
  }
}
@media (max-width: 575px) {
  .about .my-photo .emblem {
    right: -1em;
    top: -1em;
  }
}
.about .my-photo .img-detect {
  position: relative;
  z-index: 10;
}

.about .my-photo img {
  border-radius: 50%;
  height: var(--height);
  min-width: var(--width);
  width: var(--width);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.about .my-photo .dashed-circle {
  height: var(--height);
  left: 5em;
  position: absolute;
  top: 6em;
  width: var(--width);
  z-index: 9;
}

@media (max-width: 768px) {
  .about .my-photo .dashed-circle {
    left: 2em;
    top: 5em;
  }
}
.about .about-txt {
  margin-left: calc(var(--space-xxxl) * 1.2);
}

.about .about-txt p {
  font-size: clamp(0.1rem, 0.11rem + 4vw, 1.5rem);
  max-width: 74ch;
}

@media (max-width: 768px) {
  .about .about-txt {
    margin-left: 0;
    margin-top: var(--space-xl);
    max-width: 100%;
  }
}
.about .txt-know-me {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  bottom: 4%;
  color: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: 28%;
  margin: 0;
  position: absolute;
  text-transform: uppercase;
  z-index: 12;
}

@media (max-width: 1400px) {
  .about .txt-know-me {
    left: 1%;
    top: 11%;
    line-height: 1.1;
  }
}
@media (max-width: 768px) {
  .about .txt-know-me {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-size: var(--font-size-lg);
    right: 11%;
    top: 33%;
  }
}
@media (max-width: 575px) {
  .about .txt-know-me {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-size: var(--font-size-md);
    left: 4%;
    top: 5%;
  }
}
.half-split-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 768px) {
  .half-split-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.half-split-container .half-split-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
}

/*
.clean-list::-webkit-scrollbar {
    width: 10px;
}
.clean-list::-webkit-scrollbar-track {
    background-color: darkgrey;
}
.clean-list::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*/
/* html {
    scrollbar-color: #6969dd #e0e0e0;
    scrollbar-width: thin;
} */
/* custom scrollbar */
body::-webkit-scrollbar {
  width: 20px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  transition: scrollbar-color 0.3s ease-out;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary-darker);
}

.clean-list {
  /* background: var(--color-bg-light); */
  list-style-type: circle;
  padding: 3em 4em;
  background: linear-gradient(-45deg, var(--color-bg-light), var(--color-neutral-lighter));
  background-size: 400% 400%;
  animation: gradientBG 7s ease infinite;
  margin: 1em;
  border-radius: 0.5em;
  max-height: 70vh;
  overflow-y: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
  /* 
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  scrollbar-color: #d4aa70 #e4e4e4; 
  transition: scrollbar-color 0.3s ease-out; */
}

/* .clean-list::-webkit-scrollbar {
    width: 10px;
}

.clean-list::-webkit-scrollbar-track {
    background-color: #e4e4e4;
        border-radius: 100px;
}

.clean-list::-webkit-scrollbar-thumb {
    border-radius: 100px;
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
} */
.clean-list::-webkit-scrollbar {
  width: 11px;
}

.clean-list::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.clean-list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-image: linear-gradient(180deg, var(--color-neutral) 0%, var(--color-neutral) 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5);
  /* border: 6px solid rgba(0, 0, 0, 0.18); */
  /* border-left: 0; */
  /* border-right: 0; */
  /* background-color: #8070d4; */
}

.clean-list:hover::-webkit-scrollbar-thumb {
  /* scrollbar-color: #5749d2; */
  background-image: linear-gradient(180deg, var(--color-neutral) 0%, var(--color-neutral) 99%);
}

.clean-list li {
  color: var(--color-text-light);
  line-height: 1.8;
  font-size: clamp(1rem, 2vw, 1.5rem);
  padding-inline-start: 1ch;
  /* transition: all .33s ease-in-out; */
  /* text-decoration: none; */
  /* position: relative; */
  /* width: calc(100%); */
  /* background-image: linear-gradient(transparent calc(100% - 10px), black 10px) left bottom no-repeat; */
  /* background: linear-gradient(90deg, var(--color-accent), var(--color-primary)) left bottom no-repeat; */
  /* background-size: 0% 4px; */
  /* background-size: 0% 100%; */
  /* transition: background-size 1s; */
  /* margin: 6px 0 !important; */
  /* padding: 1em !important; */
  /* margin: 0 10px 18px; */
  /* display: initial;
  background: #ffffffb5;
  border-radius: 8px;
  line-height: auto;
  padding: 14px;
  margin-right: 8px;
  line-height: 3; */
}

/* .clean-list li:nth-child(odd) {
    background: #e1e1e1;
} */
.clean-list li:hover {
  /* background-size: 100% 4px; */
  /* background-size: 100% 100%; */
  /* this will give another kind of animation (all lines will animate at the same time)*/
  /* -webkit-box-decoration-break: clone; */
  /* box-decoration-break: clone; */
}

.clean-list li::marker {
  content: "›"/"";
  color: var(--color-accent);
  transition: all 0.33s ease-in-out;
}

.clean-list li:hover::marker {
  color: var(--color-primary);
}

.heading-lg {
  font-size: clamp(1.3rem, 4vw, 2.2rem);
}

header {
  margin: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animated-logo-name {
  max-width: 45ch;
  text-align: center;
  font-size: 10vw;
}

/*
.animated-logo-name .splitting-anim {
    max-width: 45ch;
    text-align: center;
    font-size: clamp(3rem, 6.5vw, 11rem);
    margin: 0;
    margin-bottom: 0.11em;
    line-height: 1;
}*/
.block {
  display: block;
}

header h2 {
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-weight: 333;
  color: var(--color-text);
  margin: 0;
  padding: 0;
  margin-bottom: 0.83em;
  text-align: center;
}

/*
.splitting {
    --text-weight-min: 100;
    --text-weight-max: 900;
    --text-slant-min: 1;
    --text-slant-max: 12;
    --text-width-min: 75;
    --text-width-max: 100;
    --glow-hue-min: 0;
    --glow-hue-max: 255;
    --glow-size-min: 0;
    --glow-size-max: 15;
    --glow-lightness: 57%;
    --delay: 0;
    --duration: 3s;
    --percent: 0;
}

.char {
    font-stretch: var(--text-width);
    font-style: oblique var(--text-slant);
    font-weight: var(--text-weight);
    animation: wave var(--duration) ease-in-out var(--delay) infinite alternate;
    animation-fill-mode: backwards;
    --percent: calc(var(--char-index) / var(--char-total));
    // --val1: calc(2 * var(--percent) - 1);
    // --val2: calc((var(--val1) * var(--val1)) * -1 + 1);
    --delay: calc(var(--percent) * var(--duration));
    --glow-hue: calc(var(--percent) * 510);
}
*/
/*
@font-face {
    font-family: "Roboto VF";
    src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/57225/Roboto-VF.woff2") format("woff2-variations");
    font-stretch: 75% 100%;
    font-style: oblique 0deg 12deg;
    font-weight: 100 900;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;400&&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

:root {
    --text-weight: 500;
    --text-width: 100;
    --text-slant: 0;
}

body {
    font-family: "Roboto VF", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}*/
:root {
  --text-weight-min: 100;
  --text-weight-max: 900;
  --text-slant-min: 1;
  --text-slant-max: 12;
  --text-width-min: 75;
  --text-width-max: 100;
  --glow-hue-min: 0;
  --glow-hue-max: 255;
  --glow-size-min: 0;
  --glow-size-max: 15;
  --glow-lightness: 57%;
  --delay: 0;
  --duration: 3s;
  --percent: 0;
  --text-weight: 500;
  --text-width: 100;
  --text-slant: 0;
}

h1.name-anim {
  /*   font-weight: 100;
  font-size: 10vw;
  font-variation-settings: "wght" 100, "wdth" 85; */
  /*   animation: breathe 4000ms infinite forwards; */
  max-width: 45ch;
  text-align: center;
  font-size: clamp(3rem, 5.8vw, 11rem);
  margin: 0;
  margin-bottom: 0.11em;
  line-height: 1;
  /* font-stretch: var(--text-width-min);
  font-style: oblique var(--text-slant-min);
  font-weight: var(--text-weight-min); */
}

.name {
  letter-spacing: -0.11em;
}

.whitespace {
  margin-inline: 0.11em;
}

/*
h1 span:nth-child(1) {
  animation-delay: 200ms;
}

h1 span:nth-child(2) {
  animation-delay: 400ms;
}

h1 span:nth-child(3) {
  animation-delay: 800ms;
}
h1 span:nth-child(4) {
  animation-delay: 1200ms;
}
h1 span:nth-child(5) {
  animation-delay: 140s;
}
h1 span:nth-child(6) {
  animation-delay: 1600ms;
}
h1 span:nth-child(7) {
  animation-delay: 1800ms;
}
h1 span:nth-child(8) {
  animation-delay: 2000ms;
}
*/
h1.anim-name .char {
  /*   --h: calc(var(--char-index, 0) * 10deg);
  --color: hsl(var(--h), 80%, 50%);
   color: var(--color); */
  --delay: calc((var(--char-index) + 1) * 300ms);
  /*   animation: breathe 5000ms infinite both; */
  animation-delay: var(--delay);
  font-stretch: var(--text-width);
  font-style: oblique var(--text-slant);
  /* font-weight: var(--text-weight); */
  font-weight: var(--text-weight-max);
  animation: wave var(--duration) ease-in-out var(--delay) infinite alternate;
  animation-fill-mode: backwards;
  --percent: calc(var(--char-index) / var(--char-total));
  /*     // --val1: calc(2 * var(--percent) - 1); */
  /*     // --val2: calc((var(--val1) * var(--val1)) * -1 + 1); */
  --delay: calc(var(--percent) * var(--duration));
  --glow-hue: calc(var(--percent) * 510);
  /*       font-stretch: var(--text-width);
  font-style: oblique var(--text-slant);
    font-weight: var(--text-weight);
    animation: wave var(--duration) ease-in-out var(--delay) infinite alternate;
    animation-fill-mode: backwards;
    --percent: calc(var(--char-index) / var(--char-total)); */
  /*     --val1: calc(2 * var(--percent) - 1); */
  /*     --val2: calc((var(--val1) * var(--val1)) * -1 + 1); */
  /*     --delay: calc(var(--percent) * var(--duration)); */
}

h1.name-anim:hover .char {
  --delay: calc((var(--char-index) + 1) * 300ms);
  /*   animation: breathe 5000ms infinite both; */
  animation-delay: calc(var(--delay) - 1);
  font-stretch: var(--text-width-max);
  font-style: oblique var(--text-slant-max);
  /* font-weight: var(--text-weight); */
  font-weight: var(--text-weight-max);
  animation: hoverLogo var(--duration) ease-in-out var(--delay) infinite alternate;
  animation-fill-mode: backwards;
  --percent: calc(var(--char-index) / var(--char-total));
  /*     // --val1: calc(2 * var(--percent) - 1); */
  /*     // --val2: calc((var(--val1) * var(--val1)) * -1 + 1); */
  --delay: calc(var(--percent) * var(--duration));
  --glow-hue: calc(var(--percent) * 510);
  /* font-variation-settings: "wght" var(--font-weight, 100); */
  font-variation-settings: "wght" var(--text-weight-min), "wdth" var(--text-width-min), "slnt" var(--text-slant-min);
}

@keyframes wave {
  0% {
    /* font-variation-settings: "wght"var(--text-weight-min), "wdth"var(--text-width-min), "slnt"var(--text-slant-min); */
    /* font-variation-settings: "wght"var(--text-weight-min); */
    text-shadow: 0 0 calc(var(--glow-size-min) * 1px) hsla(var(--glow-hue), 100%, var(--glow-lightness), 1);
  }
  50% {
    /* font-variation-settings: "wght"var(--text-weight-max), "wdth"var(--text-width-max), "slnt"var(--text-slant-max); */
    /* font-variation-settings: "wght"var(--text-weight-max); */
    text-shadow: 0 0 calc(var(--glow-size-max) * 1px) hsla(calc(var(--glow-hue) + 255), 100%, var(--glow-lightness), 1);
  }
  100% {
    /* font-variation-settings: "wght"var(--text-weight-min), "wdth"var(--text-width-min), "slnt"var(--text-slant-min); */
    /* font-variation-settings: "wght"var(--text-weight-min); */
    text-shadow: 0 0 calc(var(--glow-size-min) * 1px) hsla(var(--glow-hue), 100%, var(--glow-lightness), 1);
  }
}
@keyframes hoverLogo {
  0% {
    font-variation-settings: "wght" var(--text-weight-max), "wdth" var(--text-width-max), "slnt" calc(var(--text-slant-min) - 1);
    /* font-variation-settings: "wght"var(--text-weight-min); */
    /* font-variation-settings: "wght"var(--text-weight-min), "wdth"var(--text-width-max), "slnt"calc(var(--text-slant-min) - 1); */
    text-shadow: 0 0 calc(var(--glow-size-min) * 1px) hsla(var(--glow-hue), 100%, var(--glow-lightness), 1);
  }
  50% {
    font-variation-settings: "wght" var(--text-weight-max), "wdth" var(--text-width-max), "slnt" var(--text-slant-max);
    /* font-variation-settings: "wght"var(--text-weight-max); */
    text-shadow: 0 0 calc(var(--glow-size-max) * 1px) hsla(calc(var(--glow-hue) + 255), 100%, var(--glow-lightness), 1);
  }
  100% {
    font-variation-settings: "wght" var(--text-weight-min), "wdth" var(--text-width-min), "slnt" var(--text-slant-min);
    /* font-variation-settings: "wght"var(--text-weight-min); */
    text-shadow: 0 0 calc(var(--glow-size-min) * 1px) hsla(var(--glow-hue), 100%, var(--glow-lightness), 1);
  }
}
.coming-soon {
  margin-bottom: 1.5em;
  padding: 1em;
  background: var(--color-lightest);
  display: inline-block;
}

main {
  /* width: min(83%, 100vw - 8rem);
  margin-inline: auto; */
}

@media (max-width: 768px) {
  main {
    /* width: min(83%, 100vw - 2rem); */
  }

  header {
    margin: 3em;
  }

  header h2 {
    margin-top: 1em;
  }

  .clean-list {
    padding: 2em 3em;
    margin: 0;
  }
}
.coming-soon {
  margin-bottom: 1.5em;
  padding: 1em;
  background: var(--color-lightest);
  display: inline-block;
  border-radius: 0.5em;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.codesign span:last-child {
  /* color: var(--color-link-hover); */
}

.codesign span:nth-child(-n+2) {
  color: var(--color-primary);
  text-decoration: underline;
}

.codesign span:nth-child(n+2) {
  color: var(--color-accent);
  font-weight: var(--text-bold);
}

.codesign span:nth-child(odd) {
  /* color: var(--color-primary); */
  padding-inline: 0.05em;
}

.codesign span:first-child {
  /* color: var(--color-accent); */
}

:root {
  --marquee-width: 100vw;
  --marquee-offset: 2vw;
  --marquee-move-initial: calc(-25% + var(--marquee-offset));
  --marquee-move-final: calc(-50% + var(--marquee-offset));
  --marquee-item-font-size: 8vw;
  --font-fancy-big: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.marquee {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100vw;
  font-family: var(--font-fancy-big);
}

.marquee .track {
  -webkit-text-stroke: 1.5px var(--color-darkest);
  text-stroke: 1.5px var(--color-darkest);
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-animation: marquee 18s linear infinite;
  animation: marquee 18s linear infinite;
  -webkit-animation: marquee 33s linear infinite;
  animation: marquee 33s linear infinite;
  color: transparent;
  cursor: default;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: var(--marquee-item-font-size);
  font-weight: 900;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  position: relative;
  -webkit-transform: translate3d(var(--marquee-move-initial), 0, 0);
  transform: translate3d(var(--marquee-move-initial), 0, 0);
  transition: opacity 0.4s, color 1.5s, -webkit-text-fill-color 0.5s;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  will-change: transform;
}

@media (max-width: 768px) {
  .marquee .track {
    line-height: 1.11;
  }
}
.marquee-reverse .track {
  -webkit-text-stroke: 1.5px transparent;
  text-stroke: 1.5px transparent;
  -webkit-text-fill-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.5);
  text-fill-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.5);
  -webkit-text-fill-color: var(--color-primary);
  text-fill-color: var(--color-primary);
  -webkit-animation: marquee-reverse 33s linear infinite;
  animation: marquee-reverse 33s linear infinite;
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.5);
  color: var(--color-primary);
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@-webkit-keyframes marquee-reverse {
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@keyframes marquee-reverse {
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.emblem {
  -webkit-animation: spinZ 20s linear infinite;
  animation: spinZ 20s linear infinite;
  border-radius: 50%;
  color: #003a6f;
  font-weight: 700;
  height: 20vh;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 20vh;
}

.emblem span {
  bottom: 0;
  display: inline-block;
  font-size: 1rem;
  left: 0;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0, 1);
  transition: all 0.5s cubic-bezier(0, 0, 0, 1);
}

@-webkit-keyframes spinZ {
  0% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes spinZ {
  0% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.top-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background-color: hsla(var(--color-lightest-h), var(--color-lightest-s), var(--color-lightest-l), 0.83);
  border-radius: 50%;
  bottom: 0;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 80px;
  justify-content: center;
  margin: 0 3em 3em 0;
  padding: 0.25em;
  position: fixed;
  right: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  width: 80px;
  z-index: 99;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

@media (max-width: 768px) {
  .top-link {
    height: 50px;
    margin: 0 1em 1em 0;
    width: 50px;
  }
}
.top-link.show {
  opacity: 1;
  visibility: visible;
}

.top-link.hide {
  opacity: 0;
  visibility: hidden;
}

.top-link svg {
  fill: var(--color-darkest);
  height: 12px;
  width: 24px;
}

@media (max-width: 768px) {
  .top-link svg {
    height: 9px;
    width: 18px;
  }
}
.top-link:hover {
  background-color: hsla(var(--color-lightest-h), var(--color-lightest-s), var(--color-lightest-l), 0.83);
}

.top-link:hover svg {
  fill: var(--color-darkest);
}

.screen-reader-text {
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.screen-reader-text:focus {
  clip: auto !important;
  background-color: #eee;
  -webkit-clip-path: none;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

::-moz-selection {
  background: var(--color-primary);
  color: var(--color-lightest);
}

::selection {
  background: var(--color-primary);
  color: var(--color-lightest);
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* old screens */
@media (max-width: 1024px) {
  .about .about-txt {
    margin-left: calc(var(--space-xl) * 1.2);
  }

  .about {
    --width: 18em;
    --height: 18em;
  }

  .about .about-txt p {
    font-size: clamp(1rem, 2vw, 1.5rem);
  }

  .clean-list {
    padding: 1em 1.4em;
  }
}
/* tablet */
@media (max-width: 768px) {
  .about {
    --width: 28em;
    --height: 28em;
  }

  .about .about-txt {
    margin: 0;
  }

  .about .about-txt p {
    font-size: clamp(1rem, 2.6vw, 1.5rem);
  }

  .clean-list li {
    font-size: clamp(1rem, 2.6vw, 1.5rem);
  }
}
@media (max-width: 560px) {
  body {
    overflow-x: hidden;
  }

  header {
    margin: 0;
    padding: 1.5em;
  }

  .container .site-header .header-wrapper,
.container main > section:not(.full-width),
.container .inner-container {
    width: min(83%, 100vw - 3rem);
  }

  .about {
    --width: 100%;
    --height: auto;
    max-width: 100%;
    margin: 0;
    padding: 1em 0 0;
    overflow-x: hidden;
  }

  .nav-note-settings nav ul {
    gap: 1.5em;
  }

  .emblem {
    height: 16vh;
  }

  .about .my-photo .emblem {
    right: 0em;
    top: -1em;
  }

  .about .my-photo img {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .about .my-photo .dashed-circle {
    left: 0;
    top: 2em;
  }
}
.swiper {
  width: 100%;
  height: 100%;
  max-width: 100vw;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 
        .swiper-slide {
            width: 60%;
        }

        .swiper-slide:nth-child(2n) {
            width: 40%;
        }

        .swiper-slide:nth-child(3n) {
            width: 20%;
        } */
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.portfolio-wrapper:hover article:not(:hover) {
  opacity: 0.4;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

.swiper-wrapper {
  padding-block: var(--space-xxxl);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  /* top: 0 !important; */
}

.portfolio-arrows > div:hover {
  cursor: pointer;
}

.portfolio-arrows > div {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background-color: hsla(var(--color-lightest-h), var(--color-lightest-s), var(--color-lightest-l), 0.83);
  border-radius: 50%;
  bottom: 0;
  -webkit-box-shadow: 0 0 11px 0 rgba(99, 99, 110, 0.2);
  box-shadow: 0 0 11px 0 rgba(99, 99, 110, 0.2);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 83px;
  width: 83px;
  justify-content: center;
  padding: 1.11em;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  margin-inline: 3em;
}

.portfolio-arrows > div:first-of-type {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.portfolio-arrows > div:last-of-type {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.portfolio-arrows svg {
  fill: var(--color-darkest);
  height: 12px;
  width: 24px;
  margin-bottom: 6px;
}

.portfolio-arrows > div:hover {
  background-color: hsla(var(--color-lightest-h), var(--color-lightest-s), var(--color-lightest-l), 1);
  /* transform: rotate(90deg) scale(1.11); */
}

.portfolio-arrows > div:first-of-type:hover {
  /* height: 111px;
  width: 111px;   
  margin: -2.25em 3em -6em 2.2em; */
  transform: rotate(-90deg) scale(1.11);
}

.portfolio-arrows > div:last-of-type:hover {
  /* height: 111px;
  width: 111px;   
  margin: -2.25em 2.2em -6em 3em; */
  transform: rotate(90deg) scale(1.11);
}

.portfolio-arrows > div:first-of-type:active {
  /* height: 111px;
  width: 111px;   
  margin: -2.25em 3em -6em 2.2em; */
  transform: rotate(-90deg) scale(0.83);
}

.portfolio-arrows > div:last-of-type:active {
  /* height: 111px;
  width: 111px;   
  margin: -2.25em 2.2em -6em 3em; */
  transform: rotate(90deg) scale(0.83);
}

.portfolio-arrows > div:active {
  /* transform: scale(.5); */
}

.portfolio-wrapper {
  counter-reset: term;
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5em;
}

.portfolio {
  margin-block: 6em;
  margin-bottom: var(--space-xl);
}

.portfolio article {
  /* margin-block: 3em; */
  transition: all 0.33s ease-in-out;
  /* border: dotted 2px #aaa; */
  /* padding: 3em; */
}

.portfolio-item-header {
  /* position: absolute;
  top: calc(var(--space-xxl) * -.83);
  left: 0; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.portfolio article h3 {
  font-size: clamp(0.5rem, 6vw, var(--font-size-md));
  margin: 0;
}

.portfolio-item span {
  text-transform: uppercase;
  display: none;
}

.portfolio-item :is(h1, h2, h3, h4, h5, h6) + p {
  color: var(--color-neutral);
}

.portfolio p {
  font-size: clamp(0.5rem, 6vw, var(--font-size-md));
}

.portfolio p:not(.portfolio-note) {
  display: inline-block;
  max-width: 50ch;
  margin-right: 1em;
}

.portfolio .portfolio-inner p {
  /* font-size: clamp(.5rem, 6vw, var(--font-size-sm)); */
  font-size: clamp(0.5rem, 6vw, var(--font-size-xs) * 1.5);
  margin: 0;
  text-align: center;
}

p:before {
  /* content: "";
  display: block;
  clear: both; */
}

.portfolio-item .tags {
  /* position: absolute;
  bottom: -4em;
  left: 0; */
  margin-bottom: 0;
  margin-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tags {
  /* position: relative;
  bottom: 0;
  left: 0; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: var(--space-lg);
  flex-wrap: wrap;
}

.tags p {
  /* font-size: clamp(.5rem, 6vw, var(--font-size-sm)); */
  margin: 0;
}

.tech-tools {
  text-transform: uppercase;
  margin-bottom: var(--space-xs);
}

.portfolio-item p.tag, p.tag {
  font-size: clamp(0.5rem, 6vw, var(--font-size-xs) * 1.2);
  padding: calc(var(--space-xs) * -0.5) var(--space-xs);
  margin: 0 0.15em;
  color: white;
  border-radius: 2em;
  opacity: 0.83;
}

.tag-html {
  background-color: #ff9d00;
}

.tag-scss {
  background-color: #007aff;
}

.tag-gsap {
  background-color: #fd6f53;
}

.tag-javascript {
  background-color: #1a1a1a;
}

.tag-gulp {
  background-color: #618985;
}

.tag-nodejs {
  background-color: #1b3ab6;
}

.tag-ui {
  background-color: #617989;
}

.tag-ux {
  background-color: #c59bb7;
}

.tag-branding {
  background-color: #133a61;
}

.tag-design {
  background-color: #b85454;
}

.tag-jquery {
  background-color: #0db453;
}

.tag-react {
  background-color: #0b9143;
}

.tag-webflow {
  background-color: #3c0ee1;
}

.tag-wordpress {
  background-color: #6e92f5;
}

.tag-illustrations {
  background-color: #49493f;
}

.tag-3d {
  background-color: #d40c0c;
}

.tag-photography {
  background-color: #595a0f;
}

.tag-headlesscms {
  background-color: #383a41;
}

.portfolio article:first-child {
  /* margin-left: 14em; */
}

.portfolio article:last-child {
  /* margin-right: 28em !important; */
}

.portfolio-item {
  position: relative;
  display: flex;
  flex-direction: column;
  /* background: white; */
  /* width: 30em; */
  /* min-height: 20em; */
  /* border-top-left-radius: 30em;
  border-bottom-left-radius: 30em;
  border-top-right-radius: 30em;
  border-bottom-right-radius: 30em; */
  border-radius: 30em/30em;
  transition: all 0.33s ease-in-out;
}

.portfolio-item:nth-child(2n) {
  /* margin-top: var(--space-xl); */
}

.portfolio-item:after {
  position: absolute;
  top: 0;
  left: -0.4em;
  opacity: 0.33;
  display: none;
  color: var(--color-neutral-lighter);
  counter-increment: term;
  content: counter(term, decimal-leading-zero) ". ";
  /* font-size: 18em; */
  font-size: clamp(0.5rem, 10vw, var(--font-size-xxxxl) * 1.5);
}

.portfolio-item img {
  /* position: absolute; */
  /* max-width: 8em; */
  object-fit: cover;
  width: 100%;
  /* height: 100%; */
  /* top: 0; */
  /* left: 0; */
  /* bottom: 0; */
  /* right: 0; */
  /* z-index: -1; */
  /* min-width: 100%; */
  border-radius: 0.3em/0.3em;
  transition: all 0.33s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px 0px;
}

.portfolio-item .portfolio-item-header h3, .portfolio-item .portfolio-item-header p, .portfolio-item:hover .tags, .portfolio-item:hover .tags p.tag, .portfolio .portfolio-inner p.tag, .tags, p.tag, .tags p.tag {
  transition: all 0.33s ease-in-out;
}

.portfolio-item img:hover {
  /* transform: scale(1.05); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 35px 0px;
}

.portfolio-item:hover img {
  transform: scale(1.05);
}

.portfolio-item:hover .portfolio-item-header h3 {
  transform: translateY(-0.8em);
  transition-delay: 0.1s;
  /* font-size: 150%; */
}

.portfolio-item:hover .portfolio-item-header p {
  transform: translateY(-0.8em);
  transition-delay: 0.2s;
}

.portfolio-item:hover .tags {
  /* bottom: -2.5em;
  gap: .5em; */
  transform: translateY(1em);
  transition-delay: 0.05s;
}

.tags {
  transition-delay: 0.05s;
}

.portfolio-item:hover .tags p.tag {
  font-size: clamp(0.5rem, 6vw, var(--font-size-xs) * 1.5);
  margin-inline: 0.25em;
  /* transition-delay: .25s; */
}

.modal__content .tags p.tag {
  font-size: clamp(0.5rem, 6vw, var(--font-size-sm) * 1.25);
  margin-inline: 0.25em;
  margin-bottom: var(--space-xs);
}

.portfolio-item:hover .tags p.tag:nth-child(1), .modal__content .tags p.tag:nth-child(1) {
  transition-delay: 0.1s;
}

.portfolio-item:hover .tags p.tag:nth-child(2), .modal__content .tags p.tag:nth-child(2) {
  transition-delay: 0.2s;
}

.portfolio-item:hover .tags p.tag:nth-child(3), .modal__content .tags p.tag:nth-child(3) {
  transition-delay: 0.3s;
}

.portfolio-item:hover .tags p.tag:nth-child(4), .modal__content .tags p.tag:nth-child(4) {
  transition-delay: 0.4s;
}

.portfolio-item:hover .tags p.tag:nth-child(5), .modal__content .tags p.tag:nth-child(5) {
  transition-delay: 0.5s;
}

.portfolio-item:hover .tags p.tag:nth-child(6), .modal__content .tags p.tag:nth-child(6) {
  transition-delay: 0.6s;
}

.portfolio-item:hover .tags p.tag:nth-child(7), .modal__content .tags p.tag:nth-child(7) {
  transition-delay: 0.7s;
}

.portfolio-item:hover .tags p.tag:nth-child(8), .modal__content .tags p.tag:nth-child(8) {
  transition-delay: 0.8s;
}

.portfolio-item:hover .tags p.tag:nth-child(9), .modal__content .tags p.tag:nth-child(9) {
  transition-delay: 0.9s;
}

.portfolio a[href$=".pdf"] {
  /* background: transparent url(/img/ico-pdf-40.png) center left no-repeat; */
  background-repeat: no-repeat;
  background-image: url(/img/ico-pdf-40.png);
  padding-left: 50px;
  background-size: auto;
  background-position: 0.3em center;
}

.portfolio-note {
  padding-block: 1em;
  margin-bottom: 3em;
  border-bottom: 2px solid var(--color-text);
  color: var(--color-text);
  display: table;
  font-size: var(--font-size-md);
}

.portfolio a {
  font-size: var(--font-size-md);
  text-decoration: none;
  /* display: table; */
  /* line-height: 2; */
  color: var(--color-primary);
  transition: all 0.33s ease-in-out;
  height: 100%;
  /* display: block; */
}

.modal__links a {
  /* font-size: var(--font-size-md); */
  color: var(--color-primary);
  text-decoration: none;
  /* display: table;
  line-height: 2;
  transition: all .33s ease-in-out; */
}

.portfolio .portfolio-links a,
.modal__links a {
  transition: all 0.33s ease-in-out;
  transform: translateX(0);
}

.portfolio .portfolio-links a:hover,
.modal__links a:hover {
  color: var(--color-primary-darker);
  transform: translateX(0.3em);
}

.portfolio a:not(.link-vid)[href^=http]::after,
.portfolio a:not(.link-vid)[href^="https://"]::after,
.modal__links a:not(.link-vid)[href^=http]::after,
.modal__links a:not(.link-vid)[href^="https://"]::after {
  content: "";
  width: 20px;
  height: 20px;
  margin: 0 0 -2px 10px;
  transition: all 0.33s ease-in-out;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E"); */
  background-image: url(/img/ico-external-link-30.png);
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 18 18'%3E%3Cpath d='M13 12.09a.63.63 0 0 0-.63.63v4H1.27V5.66h4A.63.63 0 0 0 5.92 5a.63.63 0 0 0-.63-.63H.64a.61.61 0 0 0-.44.19A.6.6 0 0 0 0 5v12.37a.63.63 0 0 0 .19.44.6.6 0 0 0 .45.19H13a.6.6 0 0 0 .44-.18.63.63 0 0 0 .19-.44v-4.66a.63.63 0 0 0-.63-.63z'%3E%3C/path%3E%3Cpath class='arrow' d='M13.42 4.59A.61.61 0 0 0 13 4.4H8.54a.61.61 0 0 0-.54.39.62.62 0 0 0 .13.68l1.8 1.8-4 4a.63.63 0 0 0 .88.89l4-4 1.76 1.75a.61.61 0 0 0 .68.14.64.64 0 0 0 .39-.58V5a.6.6 0 0 0-.22-.41z'%3E%3C/path%3E%3C/svg%3E"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

/* 
.modal__links a:not(.link-vid)[href^="http"] svg path {
    transition: all .33s ease-in-out;
    fill: var(--color-primary);
    transition: fill .33s;
} */
.modal__links a:not(.link-vid)[href^=http]::after {
  /* fill: var(--color-neutral-lighter);
  transition: all .33s ease-in-out; */
}

.modal__links a:not(.link-vid)[href^=http]:hover::after,
.portfolio a:not(.link-vid)[href^="https://"]:hover::after {
  transform: translate(-2px, -4px);
  /* filter: brightness(1.1); */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 18 18'%3E%3Cpath d='M13 12.09a.63.63 0 0 0-.63.63v4H1.27V5.66h4A.63.63 0 0 0 5.92 5a.63.63 0 0 0-.63-.63H.64a.61.61 0 0 0-.44.19A.6.6 0 0 0 0 5v12.37a.63.63 0 0 0 .19.44.6.6 0 0 0 .45.19H13a.6.6 0 0 0 .44-.18.63.63 0 0 0 .19-.44v-4.66a.63.63 0 0 0-.63-.63z'%3E%3C/path%3E%3Cpath class='arrow' fill='%2386bdb7' d='M13.42 4.59A.61.61 0 0 0 13 4.4H8.54a.61.61 0 0 0-.54.39.62.62 0 0 0 .13.68l1.8 1.8-4 4a.63.63 0 0 0 .88.89l4-4 1.76 1.75a.61.61 0 0 0 .68.14.64.64 0 0 0 .39-.58V5a.6.6 0 0 0-.22-.41z'%3E%3C/path%3E%3C/svg%3E");
  filter: brightness(0.2); */
  /* fill: var(--color-primary); */
  /* filter: brightness(0.5) sepia(1) hue-rotate(-110deg) saturate(5); */
}

.arrow-moved {
  transform: translate(4px, -4px);
  border: 2px solid red;
  /* fill: var(--color-primary-darker); */
}

/* .portfolio a[href^="https://"] {
    content: "";
    display: inline-block;
    background: url(/img/ico-external-link-64.png) no-repeat top right;
    width: 14px;
    height: 12px;
} */
.link-vid {
  margin-left: 1.5em;
  text-indent: -2.5em;
}

.link-vid:before {
  content: "";
  display: block;
  background: url(/img/ico-play.png) no-repeat;
  width: 20px;
  height: 20px;
  float: left;
  margin: 11px 3em 0 -1.5em;
  background-size: contain;
}

.project-live-link svg path {
  fill: var(--color-primary);
  transition: fill 0.33s;
}

.project-live-link:hover svg path {
  fill: var(--color-neutral-lighter);
}

.project-live-link svg .arrow {
  transition: all 0.33s ease-in-out;
}

.project-live-link:hover svg .arrow {
  transform: translate(4px, -4px);
  /* fill: var(--color-primary-darker); */
}

.portfolio-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5em;
}

.project-thumb {
  aspect-ratio: auto;
  margin-left: -3em;
  min-width: calc(100% + 6em);
  margin-bottom: 2.5em;
  /* box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; */
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

a.portfolio-url {
  padding: 1em;
  background-color: var(--color-primary);
  color: var(--color-bg);
  display: inline-block;
  margin-bottom: 2em;
  text-decoration: none;
  line-height: 1.4;
  border-radius: 4px;
}

/**************************
  Basic Modal Styles
**************************/
.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal__container {
  background-color: var(--color-bg);
  color: var(--color-text);
  padding: 30px;
  height: 100%;
  width: 100%;
  /* max-width: 83vw; */
  max-width: calc(100vw - 5em);
  /* max-height: 80vh; */
  max-height: calc(100% - 5em);
  height: initial;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
}

.modal__header {
  margin: 0;
  position: relative;
  display: block;
  height: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: initial;
  margin-bottom: 0px;
}

.modal__title {
  /* position: absolute; */
  /* top: 20px; */
  /* left: 20px; */
  margin: 0.5em 0 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  letter-spacing: -0.03em;
  /* color: #00449e; */
  color: var(--color-primary);
  box-sizing: border-box;
}

.modal__container button {
  outline: none;
  cursor: pointer !important;
}

.modal .modal__close_x {
  outline: none;
  cursor: pointer !important;
  position: absolute;
  top: 0;
  right: 0;
  /* left: initial; */
  opacity: 0.88;
  z-index: 99999;
  padding: 1.5em;
  background: var(--b-bg-1);
  line-height: 0.8177;
  border-radius: 50%;
}

.modal__header .modal__close:before, .modal .modal__close:before {
  content: "✕";
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  color: var(--color-primary);
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 1em;
  margin: 0px;
  padding: 0px;
  transition: all 0.2s ease-in-out;
}

.modal__close:hover {
  transform: scale(1.5) rotate(180deg);
}

.modal__title {
  /* position: static; */
}

.modal__close, .modal__btn {
  opacity: 1;
}

.modal__content {
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  /* color: rgba(0, 0, 0, .8); */
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  padding: 10px 15px;
  background-color: #e6e6e6;
  border-radius: 4px;
  -webkit-appearance: none;
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

.modal__banner {
  margin-bottom: 8em;
}

.modal__info__container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.modal__info {
  font-size: clamp(1rem, 2vw, 1.5rem);
  max-width: 70ch;
}

.modal__intro {
  /* margin-bottom: 2em; */
  max-width: 70ch;
}

.modal__info p {
  color: var(--color-text);
}

.modal__info blockquote {
  font-family: Rubik, Arial, sans-serif;
  font-size: clamp(1rem, 2vw, 2.5rem);
  text-align: center;
  --padding: 3rem;
  padding: var(--padding);
  color: var(--color-text, black);
  font-weight: 600;
  background-color: var(--color-bg-light, white);
  /* display: grid;
  gap: 3rem; */
  background-image: radial-gradient(circle, var(--dot-color, rgba(0, 0, 0, 0.125)) calc(25% - 1px), transparent 25%);
  background-size: 0.5rem 0.5rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  justify-content: center;
  align-items: center;
  margin-block: 2em;
  border: none;
  border-top-left-radius: 2em;
  border-bottom-right-radius: 2em;
  /* border-top-right-radius: 2em;
  border-bottom-left-radius: 2em; */
}

.modal__info blockquote span {
  color: var(--color-primary);
}

/* 
.modal__info blockquote:before {
    content: '\275d';
    --qHeight: 4rem;
    content: "";
    margin-left: calc(var(--padding) * -1);
    margin-top: calc(var(--padding) * -1);
    height: var(--qHeight);
    width: calc(var(--qHeight) * 1.1);
    background-image:
        radial-gradient(circle at bottom right,
            transparent calc(var(--qHeight) / 4 - 1px),
            var(--accent-color, black) calc(var(--qHeight) / 4) calc(var(--qHeight) / 2),
            transparent calc(var(--qHeight) / 2 + 1px)),
        linear-gradient(var(--accent-color, black), var(--accent-color, black));
    background-size: calc(var(--qHeight) / 2) calc(var(--qHeight) / 2);
    background-position: top left, bottom left;
    background-repeat: space no-repeat
} */
/* blockquote:after {
    font-family: 'icons';
    background: rgba(235, 150, 108, 0.8);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    content: '\275e';
    position: absolute;
    font-size: 70px;
    line-height: 130px;
    text-align: center;
    top: 0px;
    left: 50%;
    margin-left: -65px;
    color: rgba(255, 255, 255, 0.5);
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
}

blockquote:before {
    content: '';
    width: 500px;
    height: 500px;
    border-radius: 50%;
    border-left: 5px solid rgba(235, 150, 108, 0.1);
    position: absolute;
    top: 0px;
    left: -50px;
    z-index: -1;
} */
.modal__screens {
  margin-block: 2em;
}

.half-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  margin-bottom: 2em;
}

.modal__screens img {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.project-info-links {
  margin-block: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: stretch;
}

.project-info-links a {
  width: fit-content;
}

.project-stats {
  text-transform: uppercase;
  min-width: 50%;
  text-align: right;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: 1px solid var(--color-link-hover);
}

.modal__links {
  display: flex;
  flex-direction: column;
}

.project-stats p {
  /* border-left: 1px solid var(--color-link-hover); */
  padding-left: 2em;
  margin: 0;
}

.project-stats span {
  color: var(--color-primary);
  font-weight: var(--text-bold);
}

.project-process ol {
  list-style-type: decimal-leading-zero;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, 25%); */
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  /* border-top: 1px solid black; */
  /* border-left: 1px solid black; */
  padding: 0 0 1em;
  margin: 0 0 1.5em;
  border-bottom: 2px solid var(--color-link-hover);
}

.project-process li {
  /* padding: 15px; */
  /* text-align: center; */
  /* border-bottom: 1px solid black; */
  /* border-right: 1px solid black; */
  /* position: relative; */
  font-size: clamp(0.83rem, 2vw, 1.1rem);
  color: var(--color-text);
}

.project-process li::marker {
  color: var(--color-neutral-lighter);
}

.project-details {
  counter-reset: process;
}

.project-process-details {
  font-size: clamp(1rem, 2vw, 1.1rem);
  column-count: 2;
  column-gap: 4em;
}

.project-process-details > div {
  position: relative;
  display: table;
}

.project-process-details p {
  line-height: 1.7;
}

.project-process-details > div::before {
  counter-increment: process;
  content: counter(process, decimal-leading-zero) ". ";
  position: absolute;
  left: -1.6em;
  top: 0;
  color: var(--color-neutral-lighter);
  opacity: 0.5;
  font-size: clamp(1rem, 6vw, 2rem);
  z-index: -1;
  line-height: 1;
}

.project-inner-title {
  font-weight: var(--text-bold);
  font-size: clamp(1.2rem, 4vw, 2rem);
  color: var(--color-primary);
  letter-spacing: -0.03em;
  margin-bottom: 0.5em;
  margin-top: 2em;
}

.corporate-health-store-color {
  background-color: #0a091b;
}

.canada-pharmacy-color {
  /* background-color: #3C83BF; */
  background-color: #fff;
}

.health-store-color {
  /* background-color: #e3ecf3; */
  background-color: #fff;
}

.world-pet-express-color {
  background-color: #fff;
}

.seed-stone-color {
  background-color: #fff;
}

.reship-color {
  background-color: #fff;
}

.zenabis-color {
  background-color: #fff;
}

.brand-logo {
  display: flex;
  justify-content: center;
  margin-block: 2em;
  padding-block: 4em;
}

.brand-logo img, .brand-logo picture {
  width: auto;
}

.modal__screens video {
  margin: 0;
  margin-bottom: 1.5rem;
  width: 100%;
}

.modal__screens > div {
  margin-bottom: 1.5rem;
}

.project-screen-code {
  padding: 11em;
  background-color: var(--color-neutral);
  /* background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%); */
  background-color: var(--color-primary);
  background-image: linear-gradient(315deg, var(--color-gradient) 0%, var(--color-gradient-darker) 46%, var(--color-gradient-accent) 100%);
}

.project-screen-code img {
  border-radius: 1em;
}

.main-screen {
  padding: 11em;
  /* background-color: var(--color-neutral); */
  background-color: var(--color-primary);
  background-image: linear-gradient(-315deg, var(--color-gradient) 0%, var(--color-gradient-darker) 46%, var(--color-gradient-accent) 100%);
}

.modal__screens :is(h1, h2, h3, h4, h5, h6) {
  font-weight: var(--text-bold);
  font-size: clamp(1rem, 2vw, 2rem);
  color: var(--color-bg);
  /* color: var(--color-primary); */
  letter-spacing: -0.03em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  /* margin-top: 0; */
}

.modal__screens :is(h1, h2, h3, h4, h5, h6):first-child {
  margin-top: 0;
}

.project-full-screenshot {
  /* box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px !important; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px !important; */
  /* box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px !important; */
}

/**************************
  Demo Animation Style
**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

/**************************
  Mobile custom styles for individual modals
**************************/
@media (max-width: 1400px) {
  .portfolio-links {
    grid-template-columns: repeat(2, 1fr);
    gap: 4em;
  }
}
@media (max-width: 960px) {
  .portfolio article {
    padding: 2em;
  }

  .portfolio-links {
    grid-template-columns: repeat(1, 1fr);
    gap: 3em;
  }
}
@media (max-width: 768px) {
  /* .portfolio-links {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5em;
  } */
  .portfolio {
    margin-bottom: var(--space-xxl);
  }

  .portfolio-wrapper {
    gap: 3em;
    grid-template-columns: repeat(1, 1fr);
  }

  .portfolio p:not(.portfolio-note, .tag) {
    display: grid;
    justify-content: center;
    margin-right: 0em;
  }

  .portfolio-item-header {
    margin-bottom: 0.5em;
  }

  .portfolio p:not(.portfolio-note, .tag):last-of-type {
    /* margin-bottom: 2em; */
  }

  a.portfolio-url {
    margin-bottom: 0;
  }

  .project-thumb {
    /* aspect-ratio: auto; */
    margin-left: -2em;
    min-width: calc(100% + 4em);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }

  .modal__header {
    padding: 0.4em 0;
  }

  .modal__banner {
    margin-bottom: 2em;
  }

  .modal .modal__close_x {
    padding: 0.83em;
    top: 0.83em;
  }

  .project-info-links {
    flex-direction: column-reverse;
  }

  .project-info-links a {
    /* margin-bottom: 1em; */
    margin-bottom: 0.5em;
  }

  .link-vid {
    margin-left: 0.5em;
    text-indent: 0;
  }

  .link-vid:before {
    /* margin: 11px 1em 0 -0.75em; */
    margin: 2px 0.5em 0 -0.75em;
  }

  .project-stats {
    min-width: 5.2em;
    border-left: 0;
    margin-bottom: 1em;
    padding-block: 0.5em;
    border-bottom: 1px solid var(--color-link-hover);
  }

  .project-stats p {
    border: 0;
    padding-left: 0;
  }

  .brand-logo img, .brand-logo picture {
    max-width: 83%;
  }

  .modal__info blockquote {
    --padding: 2rem;
  }

  .project-process ol {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }

  .project-process li {
    margin-left: 2em;
  }

  .portfolio-wrapper:hover article:not(:hover) {
    opacity: 1;
  }

  .project-process-details {
    column-count: 1;
  }

  .project-process-details div::before {
    content: counter(process, decimal-leading-zero);
    left: -0.83em;
    top: -0.2em;
    letter-spacing: -0.04em;
    font-size: 7em;
    opacity: 0.3;
  }

  .project-screen-code, .main-screen {
    padding: 1em;
  }

  .half-split {
    grid-template-columns: 1fr;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 24px !important;
  }

  .portfolio-item:hover .portfolio-item-header h3 {
    transform: translateY(0);
    transition-delay: 0;
    /* font-size: 150%; */
  }

  .portfolio-item:hover .portfolio-item-header p {
    transform: translateY(0);
    transition-delay: 0;
  }

  .portfolio-item .tags {
    bottom: -5em;
  }

  .portfolio-item:hover .tags {
    /* gap: .5em; */
    transform: translateY(0);
    transition-delay: 0s;
    transform: translate(0, 0);
  }

  .portfolio-item .tags p.tag {
    font-size: clamp(0.5rem, 6vw, var(--font-size-xs) * 1.5);
    margin: 0.1em 0.15em 0.3em;
    border-radius: 0.83em;
    line-height: 1.8;
    /* transition-delay: .25s; */
  }

  .portfolio-item:hover img {
    transform: scale(1);
  }

  .portfolio-item:nth-child(2n) {
    margin: 0;
  }

  .portfolio-item img {
    aspect-ratio: auto;
    position: inherit;
    border-radius: 0.5em;
  }

  .portfolio-item .tags {
    flex-wrap: wrap;
  }

  .portfolio-arrows > div {
    margin-inline: 0.25em;
    height: 50px;
    width: 50px;
  }

  .portfolio article {
    padding: 0.25em;
  }

  .portfolio-item {
    min-height: auto;
    border-radius: 0;
  }

  .swiper-button-next,
.swiper-button-prev {
    top: calc(100% - 2em) !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .modal__container {
    width: 96% !important;
    min-width: 96% !important;
  }

  @supports (display: flex) {
    .modal__container {
      width: 96% !important;
      min-width: 96% !important;
      max-width: 96% !important;
      max-height: 98%;
    }
  }
}
@media (hover: hover) and (pointer: fine) {
  .portfolio > *:hover {
    transform: translate(0, 0);
    transition-delay: 0;
  }
}